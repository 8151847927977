export default (props) => {
  const keys = Object.keys(props);

  const data = {};
  const rest = {};

  keys.forEach((key) => {
    if (key.indexOf("data-") === 0) data[key] = props[key];
    else rest[key] = props[key];
  });

  return [data, rest];
};
