/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import Header from "../header";
import "./style.css";
import Footer from "../footer";

const Layout = ({ children, brand }) => (
  <>
    <Header siteTitle={brand} />
    <div className="ldo__layout">
      <main>{children}</main>
    </div>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  brand: PropTypes.string.isRequired,
};

export default Layout;
