import React from "react";
import Link from "../link";
import "./style.css";

const footerDate = new Date().getUTCFullYear();

const Footer = (props) => (
  <footer {...props} className="ldo__footer">
    <div className="ldo__footer-column">
      <h3>Get in touch</h3>
      <hr />
      <h4>Find us on Social Media</h4>
      <hr />
      <div className="ldo__footer-row">
        <Link
          aria-label="See our twitter"
          href="https://www.twitter.com/farpointlabs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </Link>
        <Link
          aria-label="Our github"
          href="https://www.github.com/farpointlabs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </Link>
        <Link
          aria-label="See our LinkedIn"
          href="https://www.linkedin.com/company/farpointlabs"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </Link>
      </div>
      <p>
        Drop us an email at:
        <br />
        <Link href="mailTo:contact@farpointlabs.com">contact@farpointlabs.com</Link>
      </p>
      <hr />
      <p>
        Talk to someone at:
        <br />
        <Link href="tel:+442034881337">+44 203 488 1337</Link>
      </p>
      <hr />
      <p>
        Contact support at:
        <br />
        <Link href="mailTo:support@farpointlabs.com">support@farpointlabs.com</Link>
      </p>
      <hr />
      <p>
        Find out about our openings at:
        <br />
        <Link href="mailTo:join-us@farpointlabs.com">join-us@farpointlabs.com</Link>
      </p>
      <hr />
    </div>
    <div className="ldo__footer-column">
      <h3>Pop in and say hello</h3>
      <hr />
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d620.7384302490892!2d-0.13870627074625852!3d51.514065033326986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604d523fb5c39%3A0xdc09f8b001e0c19e!2sFarpoint+Labs!5e0!3m2!1sen!2suk!4v1548865042711"
        width="100%"
        height="350px"
        frameBorder="0"
        allowFullScreen
      />
      <hr />
      <div className="ldo__footer-row">
        <Link href="/cookies">Cookies</Link>
        <Link href="/privacy-policy">Privacy Policy</Link>
      </div>
      <hr />
      <Link href="/">
        <p>{`© Farpoint Labs ${footerDate} `}</p>
      </Link>
    </div>
  </footer>
);

export default Footer;
