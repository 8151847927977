/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const Checkbox = ({
  checked, error, disabled, name, valid, onInput, ...rest
}) => {
  const className = classnames(
    "ldo__checkbox-input",
    checked && "ldo__checkbox--checked",
    {
      "ldo__checkbox-input--valid": !disabled && valid,
    },
  );

  return (
    <>
      <div
        className={className}
        tabIndex={0}
        onKeyPress={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            onInput();
          }
        }}
        role="checkbox"
        aria-checked={checked}
      >
        {checked ? "👍" : ""}
      </div>
      <input
        type="checkbox"
        {...rest}
        aria-invalid={error}
        className="ldo__checkbox-field"
        disabled={disabled}
        id={name}
        name={name}
        onInput={onInput}
      />
    </>
  );
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  error: false,
  name: undefined,
  valid: false,
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onInput: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string,
  valid: PropTypes.bool,
};

export default Checkbox;
