import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const Zebra = ({ children }) => <div className="ldo__zebra-component">{children}</div>;

Zebra.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Zebra;
