import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const EmailInput = ({
  error, disabled, name, valid, value, ...rest
}) => {
  const className = classnames("ldo__email-input", {
    "ldo__email-input--valid": !disabled && valid,
  });

  return (
    <input
      {...rest}
      aria-invalid={error}
      className={className}
      disabled={disabled}
      id={name}
      name={name}
      value={value}
      type="email"
      // autoComplete="on"
    />
  );
};

EmailInput.defaultProps = {
  disabled: false,
  error: false,
  name: undefined,
  valid: false,
  value: "",
};

EmailInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
};

export default EmailInput;
