import React from "react";
import PropTypes from "prop-types";
import "./style.css";

class FieldError extends React.Component {
  render() {
    const { children, icon } = this.props;

    return (
      Boolean(children) && (
        <div aria-live="polite" className="ldo__field-error">
          {icon && (
            <span role="img" aria-label="cross">
              ❌
            </span>
          )}
          <p color="error">{children}</p>
        </div>
      )
    );
  }
}

FieldError.defaultProps = {
  children: undefined,
  icon: true,
};

FieldError.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.bool,
};

export default FieldError;
