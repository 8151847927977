import React from "react";
import PropTypes from "prop-types";
import Link from "../link";
import SlideButton from "../slide-button";
import "./style.css";

const Header = ({ siteTitle }) => (
  <header className="ldo__header">
    <Link href="/">
      <em>{siteTitle}</em>
    </Link>
    {/* <Link className="ldo__header--mobile-buttons" href="http://app.store">
      Download from the app store
    </Link>
    <div className="ldo__header--desktop-buttons">
      <SlideButton type="button">Sign up</SlideButton>
      {" "}
      <SlideButton type="button">Log in</SlideButton>
    </div> */}
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: "",
};

export default Header;
