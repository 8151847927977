import PropTypes from "prop-types";
import React from "react";
import FieldError from "../field-error";
import FieldLabel from "../field-label";
import SupportiveText from "../supportive-text";
import "./style.css";

const LabeledField = ({
  children,
  error,
  label,
  name,
  optional,
  supportiveText,
  ...rest
}) => (
  <label {...rest} htmlFor={name} className="ldo__field">
    <FieldLabel optional={optional} variation="bold">
      {label}
    </FieldLabel>
    <SupportiveText>{supportiveText}</SupportiveText>
    <FieldError>{error}</FieldError>
    <div className="ldo__field-child">{children}</div>
  </label>
);

LabeledField.defaultProps = {
  error: undefined,
  optional: false,
  supportiveText: undefined,
};

LabeledField.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.node,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  supportiveText: PropTypes.node,
};

export default LabeledField;
