import React from "react";
import PropTypes from "prop-types";
import LabeledField from "../../partial-components/labeled-field";
import Checkbox from "../../partial-components/input/checkbox";

const CheckboxField = ({
  disabled,
  error,
  label,
  name,
  optional,
  supportiveText,
  valid,
  checked,
  onInput,
  children,
  ...rest
}) => (
  <LabeledField
    error={error}
    label={label}
    name={name}
    optional={optional}
    supportiveText={supportiveText}
  >
    <Checkbox
      checked={checked}
      error={!!error}
      disabled={disabled}
      id={name}
      name={name}
      required={!optional}
      valid={valid}
      onInput={onInput}
      {...rest}
    />
  </LabeledField>
);

CheckboxField.propTypes = {
  checked: PropTypes.bool,
  onInput: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.node,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  supportiveText: PropTypes.node,
  valid: PropTypes.bool,
};

CheckboxField.defaultProps = {
  checked: false,
  children: undefined,
  disabled: false,
  error: undefined,
  optional: false,
  supportiveText: undefined,
  valid: undefined,
};

export default CheckboxField;
