import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import SlideButton from "./slide-button";
import Layout from "./layout";
import SEO from "./seo";
import ParallaxImage from "./parallax-image";
import Zebra from "./zebra";
import ZebraComponent from "./zebra-component";
import Email from "./field/email";
import Checkbox from "./field/checkbox";
import CardImage from "./images/App.png";
import Couple from "./images/couple.png";
import Lady from "./images/lady.png";
import Man from "./images/man.png";
import Waiau from "./images/Clarence.svg";
import Waimakariri from "./images/Doubs.svg";
import "./style.css";

const IndexPage = ({ brand }) => {
  const [email, setEmail] = React.useState("");
  const [consented, setConsented] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(null);

  const postAndRedirect = async (event) => {
    event.preventDefault();
    setError(null);
    axios
      .post(
        "https://dg47v0m8vl.execute-api.eu-west-2.amazonaws.com/Prod/interest",
        { name: brand, email, consented },
      )
      .then((success, err) => {
        if (!err) {
          setSubmitted(true);
        }
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          setError("Something went wrong on our side, please try again later");
        } else {
          setError(err.message);
        }
      });
  };

  //   event.preventDefault();
  //   const raw = JSON.stringify({
  //     email,
  //     consented,
  //   });
  //   const headers = new Headers();
  //   headers.append("Content-Type", "application/json");
  //   headers.append("content-length", raw.length);

  //   fetch(
  //     "https://2m7fmy42a8.execute-api.eu-west-2.amazonaws.com/Prod/signups",
  //     {
  //       method: "POST",
  //       headers,
  //       body: raw,
  //       redirect: "follow",
  //     },
  //   )
  //     .then((response) => response.text())
  //     .then(() => window.location === "/thank-you")
  //     .catch((error) => console.log("error", error));
  // };
  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <Layout brand={brand}>
      <SEO title="Home" />
      <ParallaxImage url={Waiau}>
        <div className="ldo__homepage">
          <div className="ldo__homepage--left">
            <h1>
              Meet
              {" "}
              <em>{brand}</em>
              <span className="ldo__highlight-text">.</span>
            </h1>
            <h3>
              A
              {" "}
              <span className="ldo__highlight-text"> financial advisor</span>
              {" "}
              in your pocket.
              {" "}
              <span className="ldo__highlight-text">Analyse your spending</span>
              , set savings goals and
              <span className="ldo__highlight-text">
                {" "}
                boost your credit score.
              </span>
              {" "}
            </h3>
            {submitted ? (
              <div>
                <h2>
                  <span role="img" aria-label="Thumbs up!">
                    👍
                  </span>
                </h2>
                <h3>
                  <span className="ldo__highlight-text">Congratulations!</span>
                </h3>
                <h3>
                  You&apos;ll be the first to find out about
                  {" "}
                  <span className="ldo__highlight-text">{brand}</span>
                  {" "}
when it
                  launches
                </h3>
              </div>
            ) : (
              <>
                <h3>
                  Get informed when
                  {" "}
                  <span className="ldo__highlight-text">{brand}</span>
                  {" "}
Launches
                </h3>
                <Email
                  label="Email"
                  name="above-the-fold-email"
                  value={email}
                  placeholder="someone@example.com"
                  onInput={(e) => setEmail(e.target.value)}
                  handleOnChange={handleOnChange}
                />
                <Checkbox
                  label="Send me progress updates"
                  name="above-the-fold-consent"
                  checked={consented}
                  onInput={() => setConsented(!consented)}
                >
                  Click here if you&apos;d like to stay updated with our
                  progress
                </Checkbox>
                <input
                  type="text"
                  placeholder="Don't fill this if you are human"
                  className="ldo__input-robit"
                  name="robit"
                />
                <SlideButton type="submit" onClick={postAndRedirect}>
                  Sign Up
                </SlideButton>
                {error && <p className="ldo__error">{error}</p>}
              </>
            )}
          </div>
          <div className="ldo__homepage--right">
            <img src={CardImage} alt={`The ${brand} app`} />
          </div>
        </div>
      </ParallaxImage>
      <ParallaxImage url={Waimakariri} height="halfScreen">
        <Zebra>
          <ZebraComponent>
            <div>
              <h3>The best app for home buyers</h3>
              <p>
                With our advanced AI and finance insights, we can help you get
                the best deal on your first home and demystify the credit
                market.
              </p>
            </div>
            <img
              src={Couple}
              alt={`a couple using the ${brand} app together`}
            />
          </ZebraComponent>
          <ZebraComponent>
            <div>
              <h3>The best app for savers</h3>
              <p>
                We help you plan your finances properly, and give you a helping
                hand when you need to make a big purchase.
              </p>
            </div>
            <img src={Lady} alt={`a couple using the ${brand} app together`} />
          </ZebraComponent>
          <ZebraComponent>
            <div>
              <h3>The best app for spenders</h3>
              <p>
                Don&apos;t like to spend time budgeting? Neither do we! We let
                our tools do the work for you and keep control of your spending.
              </p>
            </div>
            <img src={Man} alt={`a couple using the ${brand} app together`} />
          </ZebraComponent>
          <ZebraComponent>
            {submitted ? (
              <div>
                <h2>
                  <span role="img" aria-label="Thumbs up!">
                    👍
                  </span>
                </h2>
                <h3>
                  <span className="ldo__highlight-text">Congratulations!</span>
                </h3>
                <h3>
                  You&apos;ll be the first to find out about
                  {" "}
                  <span className="ldo__highlight-text">{brand}</span>
                  {" "}
when it
                  launches
                </h3>
              </div>
            ) : (
              <div>
                <h3>
                  Get in informed when
                  {" "}
                  <span className="ldo__highlight-text">{brand}</span>
                  {" "}
Launches
                </h3>

                <Email
                  label="Email"
                  name="above-the-fold-email"
                  value={email}
                  placeholder="someone@example.com"
                  onInput={(e) => setEmail(e.target.value)}
                  handleOnChange={handleOnChange}
                />
                <Checkbox
                  label="Send me progress updates"
                  name="above-the-fold-consent"
                  checked={consented}
                  onInput={() => setConsented(!consented)}
                >
                  Click here if you&apos;d like to stay updated with our
                  progress
                </Checkbox>
                <input
                  type="text"
                  placeholder="Don't fill this if you are human"
                  className="ldo__input-robit"
                  name="robit"
                />
                <SlideButton type="submit" onClick={postAndRedirect}>
                  Sign Up
                </SlideButton>
                {error && <p className="ldo__error">{error}</p>}
              </div>
            )}
          </ZebraComponent>
        </Zebra>
      </ParallaxImage>
    </Layout>
  );
};

IndexPage.propTypes = {
  brand: PropTypes.string.isRequired,
};

export default IndexPage;
