import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const Button = ({ children, type, onClick }) => (
  <button className="ldo__slide-button" tabIndex={0} onClick={onClick}>
    <span className="ldo__slide-button--circle">
      <span className="ldo__slide-button--icon ldo__slide-button--arrow" />
    </span>
    <span className="ldo__slide-button--text">{children}</span>
  </button>
);

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  onClick: () => {},
  type: "button",
};

export default Button;
