import React from "react";
import PropTypes from "prop-types";
import LabeledField from "../../partial-components/labeled-field";
import Email from "../../partial-components/input/email";
import dataAttributes from "../../utils/data-attributes";

const EmailField = ({
  disabled,
  error,
  label,
  name,
  optional,
  supportiveText,
  valid,
  value,
  ...rest
}) => {
  const [data, input] = dataAttributes(rest);

  return (
    <LabeledField
      {...data}
      error={error}
      label={label}
      name={name}
      optional={optional}
      supportiveText={supportiveText}
    >
      <Email
        {...input}
        error={!!error}
        disabled={disabled}
        id={name}
        name={name}
        required={!optional}
        valid={valid}
        value={value}
      />
    </LabeledField>
  );
};

EmailField.defaultProps = {
  disabled: false,
  error: undefined,
  optional: false,
  supportiveText: undefined,
  valid: undefined,
  value: undefined,
};

EmailField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.node,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  supportiveText: PropTypes.node,
  valid: PropTypes.bool,
  value: PropTypes.string,
};

export default EmailField;
