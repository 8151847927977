import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const SupportiveText = ({ children }) => Boolean(children) && (
<span className="ldo__supportive-text">
  <p>{children}</p>
</span>
);

SupportiveText.defaultProps = {
  children: undefined,
};

SupportiveText.propTypes = {
  children: PropTypes.node,
};

export default SupportiveText;
