import React from "react";
import PropTypes from "prop-types";

import "./style.css";

const FieldLabel = ({ children, optional }) => (
  <span className="ldo__field-label">
    <p variation="normal-emphasized">{children}</p>
    {optional && (
      <span className="ldo__field-label--optional">
        <p variation="normal"> (Optional)</p>
      </span>
    )}
  </span>
);

FieldLabel.defaultProps = {
  optional: false,
};

FieldLabel.propTypes = {
  children: PropTypes.node.isRequired,
  optional: PropTypes.bool,
};

export default FieldLabel;
