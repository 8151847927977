import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./style.css";

const Link = ({ children, href, onColor }) => (
  <span className="egg">
    <a className={classnames("ldo__link", onColor && "ldo__link--on-color")} href={href}>
      {children}
    </a>
  </span>
);

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  onColor: PropTypes.bool,
};

Link.defaultProps = {
  onColor: false,
};

export default Link;
